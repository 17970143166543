<template>
  <BButtonGroup>

    <!-- ASIGNATURAS -->
    <BButton
      variant="primary"
      class="btn btn-sm btn-icon rounded mt-25 mr-50"
      title="Editar asignaturas"
      @click="$emit('processGoToAsignaturas', data.item)"
    >
      <span>
        Asignaturas
      </span>
    </BButton>

    <!-- EDITAR -->
    <BButton
      v-if="$can('update', modulo)"
      variant="secondary"
      class="btn-sm mt-25 btn-icon rounded mr-50"
      :title="'Editar '+ title"
      @click="$emit('processGoToUpdate', data.item)"
    >
      <feather-icon
        icon="Edit3Icon"
      />
    </BButton>

    <!-- ELIMINAR -->
    <BButton
      v-if="$can('delete', modulo)"
      variant="secondary"
      class="btn-sm mt-25 btn-icon rounded mr-50"
      size="sm"
      @click="!estado ? '' : $emit('processRemove', data.item.id)"
      :title="!estado ? moduloCreado + ': ' + cantidad : 'Eliminar '+ title "
      :disabled="!estado"
    >
      <feather-icon
        icon="Trash2Icon"
      />
    </BButton>

  </BButtonGroup>
</template>

<script>
import {
  BButton, BButtonGroup, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    estado: {
      type: Boolean,
      default: true,
    },
    cantidad: {
      type: Number,
      default: 0,
    },
    modal: {
      type: String,
      default: 'false',
    },
    title: {
      type: String,
      default: '',
    },
    clone: {
      type: String,
      default: '',
    },
    modalClone: {
      type: String,
      default: 'false',
    },
  },
  data() {
    return {
      moduloCreado: '',
    }
  },
  mounted() {
    // switch (this.modulo) {
    //   case 'ubicaciones':
    //     this.moduloCreado = 'producto(s)'
    //     break

    //   case 'categorias':
    //     this.moduloCreado = 'producto(s)'
    //     break

    //   case 'clientes':
    //     this.moduloCreado = 'salidas'
    //     break

    //   default:
    //     break
    // }
  },
}
</script>
